/**
 * Simple `v-model` interlayer
 * @type { import("vue/types/options").ThisTypedComponentOptionsWithRecordProps<import('vue'), { visible: boolean; maxWidth: number }, { closeModal(): void }, {}, { value: boolean }> }
 */
export const ModalMixin = {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      maxWidth: 768,
      visible: false
    }
  },
  watch: {
    value: {
      handler(v) {
        this.visible = v
      },
      immediate: true
    },
    visible(v) {
      this.$emit("input", v)
    }
  },
  methods: {
    closeModal() {
      this.$emit("input", false)
    }
  }
}
